import moment from 'moment'

const isNullOrUndefined = (value) => value === undefined || value === null

const sortDate = (a, b) => moment(a).valueOf() - moment(b).valueOf()

const isToday = (date) => moment().isSame(moment(date), 'day')

const isSameDate = ({ currentDate, dateToCompare }) => {
  if (isNullOrUndefined(currentDate) || isNullOrUndefined(dateToCompare)) {
    return false
  }

  return moment(currentDate).isSame(moment(dateToCompare), 'day')
}

const formatDate = (date = '') => {
  return moment(date).isValid() ? moment(date).format('L') : date
}

const formatDateAndHour = (date) => moment(date).format('DD/MM/YYYY - HH:mm')

export { isToday, isSameDate, sortDate, formatDate, formatDateAndHour }
