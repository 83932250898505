import { useCallback, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useToast } from '@77sol/core'
import { type IQuotationFormData } from '../components/QuotationForm/types'
import { type IQuotationModalProps } from '../types'
import { useAmplitude } from 'hooks/useAmplitude'
import { quotationTracker } from 'services/tracker/events/quotation/trackers'
import { useUserHasFullRegistration } from 'hooks/useUserHasFullRegistration'
import { useCreateDimensioning } from 'domains/dimensioning/create-dimensioning/hooks/useCreateDimensioning'
import { type ICreateDimensioningPayload } from 'domains/dimensioning/create-dimensioning/services/types'
import { useCreateQuotation } from 'domains/quotation/create-quotation/hooks/useCreatingQuotation'
import { useEquipmentContainerStore } from 'containers/EquipmentQuotationContainer/store/EquipmentQuotationStore'
import { QUOTATION_SETTINGS } from '../constants/quotationSettings'
import { useQueryClient } from '@tanstack/react-query'
import { GET_ME } from 'domains/auth/me/constants/endpointKeys'

export function useQuotationModal({
  onOpenChange,
}: Pick<IQuotationModalProps, 'onOpenChange'>) {
  const history = useHistory()
  const { onSuccessOpenToast, onErrorOpenToast } = useToast()
  const { logEvent } = useAmplitude()
  const { validateUserRegistration } = useUserHasFullRegistration()
  const [isLoading, setIsLoading] = useState(false)
  const { mutateAsync: createDimensioning, isError: createDimensioningError } =
    useCreateDimensioning()
  const { reset } = useEquipmentContainerStore()

  const { mutateAsync: createQuotation, isError: createQuotationError } =
    useCreateQuotation()

  const client = useQueryClient()

  const isError = createDimensioningError || createQuotationError

  const [requestFallback, setRequestFallback] = useState<
    IQuotationFormData | undefined
  >(undefined)

  const handleCreateQuotation = useCallback(
    async (data: IQuotationFormData) => {
      // if (!validateUserRegistration()) return
      reset()
      client.invalidateQueries([GET_ME])
      logEvent(quotationTracker.actions.confirm)
      setRequestFallback(data)

      const {
        power_fase,
        voltage,
        discount,
        origin_lead,
        integrator,
        type_test,
        ...rest
      } = data

      try {
        setIsLoading(true)
        const leadType = origin_lead || 'INTEGRADOR'
        const algorithmType = type_test || 'old'

        const payload = {
          ...rest,
          origin_lead: leadType,
          type_test: algorithmType,
          quotations_quantity: QUOTATION_SETTINGS.QUANTITY,
          ...((power_fase || voltage) && {
            filter: {
              power_fase,
              voltage,
            },
          }),
          ...(discount && {
            discount: Number(discount),
          }),
          ...(integrator?.id && {
            integrator_id: Number(integrator?.id),
          }),
        }

        const data = await createDimensioning(
          payload as ICreateDimensioningPayload,
        )

        const { dimensioningId, kits } = data

        await createQuotation({
          dimensioningId,
          kits,
        })

        history.push(`/cotar/${dimensioningId as string}`)
        onOpenChange(false)
        onSuccessOpenToast('Cotação criada com sucesso!')
        setRequestFallback(undefined)
      } catch (error) {
        setIsLoading(false)
        onErrorOpenToast('Não foi possível criar a cotação!')
      }
    },
    [
      validateUserRegistration,
      createDimensioning,
      onSuccessOpenToast,
      onErrorOpenToast,
      createQuotation,
      onOpenChange,
      logEvent,
      history,
      reset,
    ],
  )

  return { isError, isLoading, handleCreateQuotation, requestFallback }
}
