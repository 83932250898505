import { useInterceptsClickAndNavigation } from 'hooks/useInterceptsClickAndNavigation'
import { useEquipmentContainerStore } from 'containers/EquipmentQuotationContainer/store/EquipmentQuotationStore'
import { ModalSaveQuotationWithWrongProduct } from './components/ModalSaveQuotationWithWrongProduct'
import { ModalSaveQuotationBeforeQuit } from './components/ModalSaveQuotationBeforeQuit'
import useObserveQuery from 'hooks/useObserverQuery'
import { type IDimensioningGETResponse } from 'domains/dimensioning/services/types'
import { GET_DIMENSIONING } from 'domains/dimensioning/constants/endpointKeys'

export function SaveQuotationBeforeQuit() {
  const {
    clickIntercepted: openModal,
    allowClick,
    handleClose,
  } = useInterceptsClickAndNavigation({
    isEnabled: true,
    excludeClassNames: ['MuiAlert-action'],
    excludeIds: [
      'notification-button',
      'equipment-container',
      'modal-remove-quotation',
      'modal-update-freight',
      'modal-add-equipment',
      'modal-change-equipment',
      'modal-delete-equipment',
      'modal-equipment-description',
      'modal-quit-quotation',
      'modal-cancel-quotation',
      'modal-checkout-equipment',
      'modal-select-client',
      'button-exit-quotation',
      'modal-cancel-proposal',
      'order-created-with-success',
      'modal-alter-delivery',
      'new-feature-informative-modal',
      'modal-alter-delivery-overlay',
      'success-order-modal-content',
      'successOrderModal_button_showValueFinancing',
      'successOrderModal_button_continue',
      'order-created-with-success-container',
      'sidebar-trigger',
      'discount-modal-content',
      'fazer_depois_complete_registration_button',
      'salvar_alteracoes_fazer_depois_complete_registration_button',
    ],
    blockPopNavigation: true,
  })

  const { dimensioningId } = useEquipmentContainerStore()

  const { data } = useObserveQuery<IDimensioningGETResponse>([
    GET_DIMENSIONING,
    dimensioningId,
  ])

  const equipmentWithError = data?.quotations.some((quotation) =>
    quotation?.products.some((product) => product.has_error),
  )

  if (equipmentWithError) {
    return (
      <ModalSaveQuotationWithWrongProduct
        open={openModal}
        onClose={handleClose}
        onSuccess={allowClick}
      />
    )
  }

  return (
    <ModalSaveQuotationBeforeQuit
      open={openModal}
      onClose={handleClose}
      onSuccess={allowClick}
    />
  )
}
