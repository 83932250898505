import { useState } from 'react'
import { Button, useToast } from '@77sol/core'
import { DeliveryAddressForm } from './components/DeliveryAddressForm'
import { BillingAddressForm } from './components/BillingAddressForm'
import { SuccessOrderModal, AlterDeliveryModal } from '../Modals'
import { INITIAL } from '../../constants'
import { useStyles } from './styles'
import { useForm, FormProvider } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { schema } from './schema.yup'
import { type ShipmentFormProps } from './ShipmentForm.types'
import { useShipmentForm } from './hooks/useShipmentForm'
import { useClearErrors } from './hooks/useClearErrors'
import { Input } from '@77sol/core'
import api from 'api'

export function ShipmentForm({
  quotation,
  proposalId,
  isCustomer,
  kit,
  quotationId,
  onCloseCheckoutModal,
}: ShipmentFormProps) {
  const classes = useStyles()
  const { onErrorOpenToast } = useToast()
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false)
  const [coupon, setCoupon] = useState('')
  const [couponError, setCouponError] = useState('')
  const [couponIsLoading, setCouponIsLoading] = useState(false)

  const { ...methods } = useForm({
    resolver: yupResolver(schema),
    reValidateMode: 'onChange',
    defaultValues: {
      deliveryAddress: INITIAL.DELIVERY_ADDRESS,
      billingAddress: INITIAL.BILLING_ADDRESS,
    },
  })

  useClearErrors({
    methods,
  })

  const [isAlterDeliveryModalOpen, setIsAlterDeliveryModalOpen] =
    useState(false)

  const {
    acceptNewDeliveryValue,
    handleCreateOrder,
    deliveryValue,
    isLoadingCreateOrder,
    addLojaNovoPedidoLoja,
    addProposalQuotationChangeDeliveryValue,
    cancelNewDeliveryValue,
  } = useShipmentForm({
    methods,
    quotationId,
    setIsAlterDeliveryModalOpen,
    quotation,
    setIsSuccessModalOpen,
  })

  const onSubmitFailed = ({ genericError = {} }: any) => {
    const {
      message = 'Preencha todos os campos obrigatórios (*) para continuar.',
    } = genericError
    onErrorOpenToast(message)
  }

  async function handleSubmitCoupon() {
    try {
      setCouponIsLoading(true)
      await api.post('/dimensioning/cupom', { cupom: coupon })
    } catch (error: any) {
      setCoupon('')
      setCouponError(error?.response?.data?.message ?? 'Cupom inválido')
    } finally {
      setCouponIsLoading(false)
    }
  }

  return (
    <>
      <div data-testid="shipment-form-container" className={classes.rightSide}>
        <FormProvider {...methods}>
          <form
            onSubmit={methods.handleSubmit(handleCreateOrder, onSubmitFailed)}
          >
            <DeliveryAddressForm kit={kit} />
            <BillingAddressForm />
            <span
              style={{
                color: 'rgb(6, 62, 249)',
                fontSize: 20,
              }}
            >
              Insira um cupom de desconto
            </span>
            <div style={{ display: 'flex', gap: 12, marginTop: 8 }}>
              <Input
                className="g-xs-12"
                name="coupon"
                value={coupon}
                onChange={(text: string) => {
                  setCouponError('')
                  setCoupon(text.toUpperCase())
                }}
                title="Cupom"
                label={'Cupom'}
                error={couponError}
                isLoading={couponIsLoading}
              />
              <Button onClick={handleSubmitCoupon}>Aplicar</Button>
            </div>

            <div className={classes.actions}>
              <Button
                id="shipmentForm_button_confirm"
                type="submit"
                disabled={coupon?.length}
                isLoading={
                  isLoadingCreateOrder ||
                  addProposalQuotationChangeDeliveryValue.isLoading
                }
                size="small"
                fullWidth
              >
                Reservar equipamentos
              </Button>
            </div>
          </form>
        </FormProvider>
      </div>

      <AlterDeliveryModal
        deliveryValue={deliveryValue}
        alterDeliveryValue={acceptNewDeliveryValue}
        cancelNewDeliveryValue={cancelNewDeliveryValue}
        isOpen={isAlterDeliveryModalOpen}
      />

      {isSuccessModalOpen && addLojaNovoPedidoLoja.data && (
        <SuccessOrderModal
          orderId={addLojaNovoPedidoLoja.data.order_id}
          onClose={() => {
            onCloseCheckoutModal()
          }}
          isOpen={isSuccessModalOpen}
        />
      )}
    </>
  )
}
