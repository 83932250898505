import { OPEN_QUOTATION_MODAL } from '../constants/endpointKeys'
import api from 'api'
import { type IMeResponse } from './types'

export const service = {
  post: async () => {
    const { data } = await api.post<IMeResponse>(OPEN_QUOTATION_MODAL, {
      open_quotation_modal: 0,
    })
    return data
  },
}
