import { type Resolver, useForm, useFormContext } from 'react-hook-form'

import { useEffect, useMemo, useState } from 'react'
import { yupResolver } from '@hookform/resolvers/yup'
import {
  type DefaultValuesForm,
  type ICreateFinancingPerQuotationFormData,
} from '../types'
import { schemaByPersonType } from '../validators/formValidator'

export function useCreateFinancingPerQuotationForm(
  defaultValues: DefaultValuesForm,
) {
  const [personType, setPersonType] = useState(defaultValues.client_type)

  const baseSchema = useMemo(() => {
    return schemaByPersonType[personType]
  }, [personType])

  const methods = useForm<ICreateFinancingPerQuotationFormData>({
    resolver: yupResolver(
      baseSchema,
    ) as Resolver<ICreateFinancingPerQuotationFormData>,
    defaultValues,
  })

  const clientType = methods.getValues('client_type')

  useEffect(() => {
    setPersonType(clientType)
  }, [clientType])

  return methods
}

export const useCreateFinancingPerQuotationFormContext = () => {
  return useFormContext<ICreateFinancingPerQuotationFormData>()
}
