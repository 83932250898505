import { ClearFieldsButton } from '../ui/ClearFieldsButton'
import { RadioPersonType } from '../ui/RadioPersonType'
import * as S from './styles'

export function Commom() {
  return (
    <S.Container>
      <section>
        <h3>Dados pessoais do cliente</h3>

        <div className="manage-person-type">
          <RadioPersonType />
          <ClearFieldsButton>Limpar dados</ClearFieldsButton>
        </div>
      </section>
    </S.Container>
  )
}
