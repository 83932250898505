/* eslint-disable no-inline-styles/no-inline-styles */
/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from 'react'
import {
  Grid,
  Hidden,
  IconButton,
  Tooltip,
  useMediaQuery,
  makeStyles,
} from '@material-ui/core'
import { Typography, Toggle, Input } from 'components'
import { Spacer, Paper, Button, Divider, Badge, useToast } from '@77sol/core'
import Modal from '@material-ui/core/Modal'
import Fade from '@material-ui/core/Fade'
import Backdrop from '@material-ui/core/Backdrop'
import { useDispatch, useSelector } from 'react-redux'
import {
  ArrowChevronForwardIcon,
  EditIcon,
  CircleActionsCloseIcon,
  DownloadIcon,
  RoomServiceHelpIcon,
  RefreshIcon,
  BagIcon,
  CircleActionsAlertInfoIcon,
  LogOutSquareIcon,
} from '@77sol/icons/dist'
import palette from 'app_palette'
import { currentEnterpriseConfigs } from 'globalConfigs/validationEnterprise'
import API from 'api'
import { parseBRL, handlePotency } from 'utils'
import * as ProposalAction from 'store/actions/index'
import { useAmplitude } from 'hooks/useAmplitude'
import { dimensioningTracker } from 'services/tracker/events/dimensioning/trackers'
import EquipmentsList from '../EquipmentsList'
import PagamentoEdit from '../PagamentoEdit'
import ModalSizingAlert from '../ModalSizingAlert'
import {
  OrcamentoPanel,
  ConcessionariaCard,
  TarifaCard,
  CorrecaoAnualCard,
  InvestimentoRetornoCard,
} from '..'
import useRedirect from 'hooks/useRedirect'
import { BudgetActions } from './components/BudgetActions'
import { useCheckQuotationKit } from 'hooks/quotation/useCheckQuotationKit'

const useStyles = makeStyles({
  modalSm: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
})

function QuoteEdit({
  proposal,
  quoteEdit,
  quotePanelEditing,
  setQuotePanelEditing,
  openConfigsEquipments,
  isFromProposalList,
  setCartQuote,
  setOpenCheckoutModal,
  handlePdf,
  setCompleteQuoteEdit,
  setQuoteIdEditing,
  setOpenConfigsEquipments,
  highlightedButton,
}) {
  const classes = useStyles({})
  const upXl = useMediaQuery((theme) => theme.breakpoints.up('xl'))
  const downLg = useMediaQuery((theme) => theme.breakpoints.down('lg'))
  const downSm = useMediaQuery((theme) => theme.breakpoints.down('sm'))
  const quotesErrors = useSelector((state) => state.Reducer1.quotesErrors)

  const quoteHasError = quotesErrors?.errors?.[quoteEdit]?.hasError

  const { logEvent } = useAmplitude()
  const { redirectTo } = useRedirect()

  const [returnData, setReturnData] = useState()

  const dispatch = useDispatch()
  const actualQuote = useSelector((state) =>
    state.Reducer1.quotes.find((quote) => quote.quotation.id == quoteEdit),
  )

  const [selectedQuote, setSelectedQuote] = useState()
  const [budgetEdit, setBudgetEdit] = useState([])
  const [methods, setMethods] = useState()

  const [showAlertDiferency, setShowAlertDiferency] = useState(false)

  const [alertQuotation, setAlertQuotation] = useState({})

  const [loadingUpdateKitPotency, setLoadingUpdateKitPotency] = useState(false)
  const [loadingChangePotency, setLoadingChangePotency] = useState(false)
  const [hideAlert, setHideAlert] = useState(false)

  // Products
  const productsCategories = proposal.categories ? proposal.categories : []
  const [productsConfigs, setProductsConfigs] = useState([])
  const [actualProductEditing, setActualProductEditing] = useState('')
  const [newTitleProduct, setNewTitleProduct] = useState('')
  const [loadingChangeLabel, setLoadingChangeLabel] = useState(false)

  const [modalRefreshBudget, setModalRefreshBudget] = useState(false)

  const [editingValue, setEditingValue] = useState(false)
  const [loadingChangeValue, setLoadingChangeValue] = useState(false)
  const [newStockValue, setNewStockValue] = useState('')
  const [loadingAttPrecification, setLoadingAttPrecification] = useState(false)

  const { onSuccessOpenToast, onErrorOpenToast } = useToast()

  const filterAddedItems = (kit, addedItems) => {
    const excludeItems = JSON.parse(addedItems)
    const fitteredItems = kit.filter(
      (product) => !excludeItems.includes(product.id),
    )
    return fitteredItems
  }

  const handleAttPrecification = () => {
    setLoadingAttPrecification(true)

    API.post('/proposal/quotation/edit/value', {
      quotation_id: quoteEdit,
    })
      .then(() => {
        onSuccessOpenToast('Precificação atualizada com sucesso!')
      })
      .catch(() => {
        onErrorOpenToast('Não foi possível atualizar a precificação!')
      })
      .finally(() => setLoadingAttPrecification(false))
  }

  const handleUpdateValue = (quote) => {
    setLoadingChangeValue(true)

    API.post('/proposal/quotation/edit/value', {
      quotation_id: quote?.quotation?.id,
      total_value: newStockValue?.split('.')?.join('')?.replace(',', '.'),
    })
      .then(() => {
        updateActualQuote(quote)
        setLoadingChangeValue(false)
        setEditingValue(false)
      })
      .catch(() => {
        setLoadingChangeValue(false)
      })
  }

  const updateActualQuote = (quote) => {
    if (quote?.quotation?.id) {
      API.get(`/proposal/quotation/${quote?.quotation?.id}`).then((res) => {
        dispatch(ProposalAction.UpdateQuoteRedux(res.data))
      })
    }
  }

  const handleUpdateProductLabel = (
    id,
    title,
    status,
    categoryId,
    categoryName,
  ) => {
    setLoadingChangeLabel(true)

    API.patch('/proposal/product/config', {
      id,
      proposal_id: proposal.proposal.id,
      category_id: categoryId,
      product_type: categoryName,
      status,
      title,
    }).then(() => {
      setLoadingChangeLabel(false)
      getProductConfigs()
      setNewTitleProduct('')
      setActualProductEditing('')
    })
  }

  const handleShowRoi = () => {
    API.get(`/proposal/quotation/roi/calculate/${quoteEdit}`).then((res) => {
      setReturnData(res.data)
    })
  }

  const getPayments = () => {
    API.get(`/proposal/quotation/payment_methods/${proposal.proposal.id}`).then(
      (res) => {
        const allMethods = res.data
        const methods = allMethods.find(
          (method) => method.id == quoteEdit,
        ).payments_methods
        setMethods(methods)
      },
    )
  }

  const handleGetBudget = (id, type, initial) => {
    API.get(`/proposal/list/budgets/${id}`).then((res) => {
      if (type == 'budget' && !initial) {
        setQuotePanelEditing('budget')
      }
      if (res.data.length == 0) {
        API.post('/proposal/create/quotation/budget/item', {
          quotation_id: id,
        }).then((res) => {
          setSelectedQuote(id)
          setBudgetEdit(res.data)
          if (type == 'investmentReturn') {
            let sizing = {}
            const quoteDimensioningId = proposal.quotes.find(
              (quote) => quote.quotation.id == id,
            ).dimensioning.id
            sizing = proposal.sizings.find(
              (sizing) => sizing.id == quoteDimensioningId,
            )
            handleShowRoi(sizing, res.data, id)
          }
        })
      } else {
        setSelectedQuote(id)
        setBudgetEdit(res.data)
        if (type == 'investmentReturn') {
          let sizing = {}
          const quoteDimensioningId = proposal.quotes.find(
            (quote) => quote.quotation.id == id,
          ).dimensioning.id
          sizing = proposal.sizings.find(
            (sizing) => sizing.id == quoteDimensioningId,
          )
          handleShowRoi(sizing, res.data, id)
        }
      }
    })
  }

  const getProductConfigs = () => {
    API.get(`/proposal/product/config/${proposal.proposal.id}`).then((res) => {
      setProductsConfigs(res.data)
    })
  }

  const handleFinish = (id) => {
    API.post('/proposal/quotation/budget/finalized', {
      quotation_id: id,
    }).then((res) => {
      dispatch(ProposalAction.UpdateQuoteDataRedux(res.data.quotation))
    })
  }

  useEffect(() => {
    handleShowRoi()
    handleGetBudget(quoteEdit, 'budget', true)
    getProductConfigs()
    getPayments()
    return () => {
      handleFinish(quoteEdit)
    }
  }, [quoteEdit])

  useEffect(() => {
    if (quotePanelEditing == '') {
      handleFinish(quoteEdit)
    }
  }, [quotePanelEditing])

  const { validateQuotationKit } = useCheckQuotationKit({
    callbackKitHasNotChanged: (id) => openCheckoutModal(id),
  })

  function openCheckoutModal(id) {
    if (actualQuote?.firstKit?.providers) return

    logEvent(dimensioningTracker.actions.buttonOrderBook)
    setCartQuote(id)
    setOpenCheckoutModal(id)
  }

  const handleUpdateKitPotency = (value, quoteId) => {
    setLoadingUpdateKitPotency(true)

    const finalValue = parseFloat(value)

    API.post('/proposal/quotation/update/kit', {
      quotation_id: quoteId,
      potency: finalValue,
    })
      .then((res) => {
        setShowAlertDiferency(false)
        dispatch(ProposalAction.UpdateQuoteRedux(res.data))
        onSuccessOpenToast('Cotação atualizada com sucesso!')
      })
      .catch(() => {
        onErrorOpenToast('Não foi possível alterar a cotação!')
      })
      .finally(() => setLoadingUpdateKitPotency(false))
  }

  const handleChangePotency = (value) => {
    setLoadingChangePotency(true)

    const finalValue = parseFloat(value)

    API.post('/proposal/dimensioning/update/potency', {
      dimensioning_id: alertQuotation.dimensioning.id,
      potency: finalValue,
    })
      .then((res) => {
        dispatch(ProposalAction.UpdateSizingRedux(res.data))
        onSuccessOpenToast('Dimensionamento atualizado com sucesso!')
        setShowAlertDiferency(false)
      })
      .catch(() => {
        onErrorOpenToast('Não foi possível alterar o dimensionamento!')
      })
      .finally(() => setLoadingChangePotency(false))
  }

  function redirectToFinancingPage() {
    redirectTo(`/financiamentos/cotacao/${actualQuote?.quotation?.id}`)
  }

  function getReserveOrderIconColor() {
    if (quoteHasError) {
      return palette.grayscale[400]
    }

    if (
      highlightedButton?.quotation_id === quoteEdit &&
      highlightedButton?.isHighlightedButton
    ) {
      return palette.primary[300]
    }
    return palette.grayscale[700]
  }

  function getDownloadPdfIconColor() {
    if (quoteHasError) {
      return palette.grayscale[400]
    }

    if (downSm) {
      return palette.white
    }

    return palette.black
  }

  return (
    <div
      style={{
        display: 'flex',
        height: downSm
          ? 'calc(100% - 82px)'
          : isFromProposalList
          ? 'calc(100% - 112px)'
          : 'calc(100% - 102px)',
      }}
    >
      <div
        style={{
          padding: '24px 32px',
          display:
            (downSm || (isFromProposalList && !upXl)) &&
            quotePanelEditing &&
            'none',
          width: downSm
            ? '100%'
            : quotePanelEditing
            ? upXl
              ? '436px'
              : '401px'
            : upXl
            ? '784px'
            : '436px',
          height: isFromProposalList ? 'calc(100% - 34px)' : '100%',
          overflowY: 'auto',
        }}
      >
        <Grid container spacing={1}>
          {false && (
            <Grid item xs={12} md={12} lg={12} xl={quotePanelEditing ? 12 : 12}>
              <Paper
                style={{
                  padding: '16px',
                  backgroundColor: palette.primary[300],
                  display: 'flex',
                  justifyContent: 'space-between',
                  flexDirection: downLg && 'column',
                }}
              >
                <div style={{ display: 'flex' }}>
                  <BagIcon
                    width="24"
                    color={palette.white}
                    style={{ minWidth: '24px', minHeight: '24px' }}
                  />
                  <Spacer
                    size="16"
                    direction="vertical"
                    style={{ minWidth: '16px' }}
                  />
                  <div>
                    <Typography color="white" type="link_small">
                      Os equipamentos dessa cotação já estão reservados
                    </Typography>
                    <Typography color="white" type="link_x_small">
                      Pedido - #00207
                    </Typography>
                  </div>
                </div>
                <Button
                  style={{
                    border: '2px solid white',
                    color: 'white',
                    marginTop: downLg && '8px',
                  }}
                  variant="outlined"
                  size="small"
                >
                  Ver pedido
                </Button>
              </Paper>
            </Grid>
          )}
          {actualQuote?.quotation?.quantify_financing &&
          actualQuote?.quotation?.quantify_financing !== 0 ? (
            <Grid item xs={12} md={12} lg={12} xl={quotePanelEditing ? 12 : 12}>
              <Paper
                style={{
                  height: 'auto',
                  backgroundColor:
                    quotePanelEditing == 'budget' && palette.primary[300],
                  borderRadius: '16px',
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                  }}
                >
                  <Typography
                    type={
                      downSm
                        ? 'link_small'
                        : upXl
                        ? 'link_medium'
                        : 'text_small'
                    }
                    color="grayscale"
                    colorWeight="700"
                  >
                    Financiamento
                  </Typography>

                  <Tooltip title="Financiamentos">
                    <IconButton aria-label="Financiamentos">
                      <LogOutSquareIcon
                        color="primary"
                        width="25"
                        onClick={redirectToFinancingPage}
                      />
                    </IconButton>
                  </Tooltip>
                </div>
                <Spacer size="6" direction="horizontal" />
                <Typography
                  type="text_x_small"
                  color="grayscale"
                  colorWeight="600"
                >
                  {actualQuote?.quotation?.quantify_financing}{' '}
                  {actualQuote?.quotation?.quantify_financing > 1
                    ? 'Simulações'
                    : 'Simulação'}
                </Typography>
              </Paper>
            </Grid>
          ) : (
            ''
          )}
          <Grid item xs={12} md={12} lg={12} xl={quotePanelEditing ? 12 : 12}>
            <Paper
              style={{
                height: 'auto',
                backgroundColor:
                  quotePanelEditing == 'equipments' && palette.primary[300],
                borderRadius: '16px',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <Typography
                  type={
                    downSm ? 'link_small' : upXl ? 'link_medium' : 'text_small'
                  }
                  color={
                    quotePanelEditing == 'equipments' ? 'white' : 'grayscale'
                  }
                  colorWeight="700"
                >
                  Equipamentos
                </Typography>
                {quotePanelEditing == 'equipments' ? (
                  <ArrowChevronForwardIcon width="16" color={palette.white} />
                ) : downSm || quotePanelEditing ? (
                  <div style={{ display: 'flex' }}>
                    {(!proposal.quotes.find(
                      (quote) => quote.quotation.id == quoteEdit,
                    )?.quotation?.filter_stock ||
                      proposal.quotes.find(
                        (quote) => quote.quotation.id == quoteEdit,
                      )?.quotation?.filter_stock == '77sol') && (
                      <Tooltip title="Baixar cotação">
                        <div style={{ display: 'flex' }}>
                          <DownloadIcon
                            id="quoteEdit_button_downloadPdf"
                            onClick={() => {
                              if (quoteHasError) {
                                return
                              }
                              handlePdf(
                                proposal.quotes.find(
                                  (quote) => quote.quotation.id == quoteEdit,
                                ),
                              )
                            }}
                            width="24"
                            color={palette.grayscale[quoteHasError ? 400 : 700]}
                            style={{ cursor: 'pointer' }}
                          />
                        </div>
                      </Tooltip>
                    )}
                    {(!proposal.quotes.find(
                      (quote) => quote.quotation.id == quoteEdit,
                    )?.quotation?.filter_stock ||
                      proposal.quotes.find(
                        (quote) => quote.quotation.id == quoteEdit,
                      )?.quotation?.filter_stock == '77sol') && (
                      <>
                        <Spacer size="24" direction="vertical" />
                        {proposal.quotes.find(
                          (quote) => quote.quotation.id == quoteEdit,
                        )?.quotation?.reserved ? (
                          <BagIcon width="24" color={palette.grayscale[700]} />
                        ) : (
                          <Tooltip
                            title={
                              proposal.quotes.find(
                                (quote) => quote.quotation.id == quoteEdit,
                              )?.quotation?.reserved
                                ? currentEnterpriseConfigs.orderGeneratedText
                                : currentEnterpriseConfigs.textToGenerateOrder
                            }
                          >
                            <div style={{ display: 'flex' }}>
                              {proposal.quotes.find(
                                (quote) => quote.quotation.id == quoteEdit,
                              )?.quotation?.reserved ? (
                                <BagIcon
                                  width="24px"
                                  color={palette.primary[300]}
                                />
                              ) : (
                                <RoomServiceHelpIcon
                                  id="quoteEdit_button_reserve"
                                  width="24"
                                  onClick={() => {
                                    if (quoteHasError) {
                                      return
                                    }
                                    validateQuotationKit(quoteEdit)
                                  }}
                                  color={getReserveOrderIconColor()}
                                  style={{
                                    cursor: actualQuote?.firstKit?.providers
                                      ? 'not-allowed'
                                      : 'pointer',
                                  }}
                                />
                              )}
                            </div>
                          </Tooltip>
                        )}
                      </>
                    )}
                    <Spacer size="24" direction="vertical" />
                    <Tooltip title="Editar">
                      <EditIcon
                        id="quoteEdit_button_editEquipment"
                        onClick={() => setQuotePanelEditing('equipments')}
                        width="24"
                        color={palette.grayscale[700]}
                        style={{ cursor: 'pointer' }}
                      />
                    </Tooltip>
                  </div>
                ) : (
                  <>
                    <Hidden xlUp>
                      <div style={{ display: 'flex' }}>
                        {(!proposal.quotes.find(
                          (quote) => quote.quotation.id == quoteEdit,
                        )?.quotation?.filter_stock ||
                          proposal.quotes.find(
                            (quote) => quote.quotation.id == quoteEdit,
                          )?.quotation?.filter_stock == '77sol') && (
                          <Tooltip title="Baixar cotação">
                            <div style={{ display: 'flex' }}>
                              <DownloadIcon
                                id="quoteEdit_button_downloadPdf"
                                onClick={() => {
                                  if (quoteHasError) {
                                    return
                                  }
                                  handlePdf(
                                    proposal.quotes.find(
                                      (quote) =>
                                        quote.quotation.id == quoteEdit,
                                    ),
                                  )
                                }}
                                width="24"
                                color={getDownloadPdfIconColor()}
                                style={{ cursor: 'pointer' }}
                              />
                            </div>
                          </Tooltip>
                        )}
                        {(!proposal.quotes.find(
                          (quote) => quote.quotation.id == quoteEdit,
                        )?.quotation?.filter_stock ||
                          proposal.quotes.find(
                            (quote) => quote.quotation.id == quoteEdit,
                          )?.quotation?.filter_stock == '77sol') && (
                          <>
                            <Spacer size="24" direction="vertical" />
                            <Tooltip
                              title={
                                proposal.quotes.find(
                                  (quote) => quote.quotation.id == quoteEdit,
                                )?.quotation?.reserved
                                  ? currentEnterpriseConfigs.orderGeneratedText
                                  : currentEnterpriseConfigs.textToGenerateOrder
                              }
                            >
                              <div style={{ display: 'flex' }}>
                                {proposal.quotes.find(
                                  (quote) => quote.quotation.id == quoteEdit,
                                )?.quotation?.reserved ? (
                                  <BagIcon
                                    width="24px"
                                    color={palette.primary[300]}
                                  />
                                ) : (
                                  <RoomServiceHelpIcon
                                    width="24"
                                    onClick={() => {
                                      if (quoteHasError) {
                                        return
                                      }
                                      validateQuotationKit(quoteEdit)
                                    }}
                                    color={getReserveOrderIconColor()}
                                    style={{
                                      cursor: actualQuote?.firstKit?.providers
                                        ? 'not-allowed'
                                        : 'pointer',
                                    }}
                                  />
                                )}
                              </div>
                            </Tooltip>
                          </>
                        )}
                        <Spacer size="24" direction="vertical" />
                        <Tooltip title="Editar">
                          <EditIcon
                            id="quoteEdit_button_editEquipment"
                            onClick={() => setQuotePanelEditing('equipments')}
                            width="24"
                            color={palette.grayscale[700]}
                            style={{ cursor: 'pointer' }}
                          />
                        </Tooltip>
                      </div>
                    </Hidden>
                    <Hidden lgDown>
                      <div style={{ display: 'flex' }}>
                        {(!proposal.quotes.find(
                          (quote) => quote.quotation.id == quoteEdit,
                        )?.quotation?.filter_stock ||
                          proposal.quotes.find(
                            (quote) => quote.quotation.id == quoteEdit,
                          )?.quotation?.filter_stock == '77sol') && (
                          <Button
                            id="quoteEdit_button_downloadPdf"
                            variant="outlined"
                            noMargin
                            size="small"
                            startIcon={<DownloadIcon />}
                            disabled={quoteHasError}
                            onClick={() => {
                              if (quoteHasError) {
                                return
                              }
                              handlePdf(
                                proposal.quotes.find(
                                  (quote) => quote.quotation.id == quoteEdit,
                                ),
                              )
                            }}
                          >
                            Exportar
                          </Button>
                        )}
                        {(!proposal.quotes.find(
                          (quote) => quote.quotation.id == quoteEdit,
                        )?.quotation?.filter_stock ||
                          proposal.quotes.find(
                            (quote) => quote.quotation.id == quoteEdit,
                          )?.quotation?.filter_stock == '77sol') && (
                          <>
                            <Spacer size="16" direction="vertical" />
                            <div style={{ display: 'flex' }}>
                              <Button
                                variant={
                                  highlightedButton?.quotation_id ===
                                    quoteEdit &&
                                  highlightedButton?.isHighlightedButton
                                    ? 'contained'
                                    : 'outlined'
                                }
                                noMargin
                                size="small"
                                startIcon={
                                  proposal.quotes.find(
                                    (quote) => quote.quotation.id == quoteEdit,
                                  )?.quotation?.reserved ? (
                                    <BagIcon />
                                  ) : (
                                    <RoomServiceHelpIcon />
                                  )
                                }
                                disabled={
                                  actualQuote?.firstKit?.providers ||
                                  quoteHasError
                                }
                                onClick={() => {
                                  if (quoteHasError) {
                                    return
                                  }
                                  if (
                                    !proposal.quotes.find(
                                      (quote) =>
                                        quote.quotation.id == quoteEdit,
                                    )?.quotation?.reserved
                                  ) {
                                    validateQuotationKit(quoteEdit)
                                  }
                                }}
                              >
                                {proposal.quotes.find(
                                  (quote) => quote.quotation.id == quoteEdit,
                                )?.quotation?.reserved
                                  ? currentEnterpriseConfigs.orderGeneratedText
                                  : currentEnterpriseConfigs.textToGenerateOrder}
                              </Button>
                            </div>
                          </>
                        )}
                        <Spacer size="16" direction="vertical" />
                        <Button
                          variant="outlined"
                          noMargin
                          size="small"
                          onClick={() => setQuotePanelEditing('equipments')}
                        >
                          Editar
                        </Button>
                      </div>
                    </Hidden>
                  </>
                )}
              </div>
              <Spacer size={upXl ? '4' : '10'} direction="horizontal" />
              <div>
                <Typography
                  type="text_x_small"
                  color={
                    quotePanelEditing == 'equipments' ? 'white' : 'grayscale'
                  }
                  colorWeight="600"
                >
                  Valor total de equipamentos
                </Typography>
                <Spacer size="10" direction="horizontal" />
                {editingValue ? (
                  <Input
                    value={newStockValue}
                    setValue={setNewStockValue}
                    size="small"
                    placeholder="Valor total"
                    currencyFormat
                    onBlur={() =>
                      handleUpdateValue(
                        proposal.quotes.find(
                          (quote) => quote.quotation.id == quoteEdit,
                        ),
                      )
                    }
                    loading={loadingChangeValue}
                  />
                ) : (
                  <div style={{ display: 'flex', alignItems: 'baseline' }}>
                    <Typography
                      type="text_x_small"
                      color={
                        quotePanelEditing == 'equipments'
                          ? 'white'
                          : 'grayscale'
                      }
                      colorWeight="600"
                    >
                      R$
                    </Typography>
                    <Spacer size="4" direction="vertical" />
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <Typography
                        type={
                          downSm
                            ? 'display_x_small_bold'
                            : upXl
                            ? 'display_small_bold'
                            : 'display_x_small_bold'
                        }
                        color={
                          quotePanelEditing == 'equipments'
                            ? 'white'
                            : 'grayscale'
                        }
                        colorWeight="700"
                      >
                        {parseBRL(
                          parseFloat(actualQuote?.quotation.equipment_value),
                        ).replace('R$', '')}
                      </Typography>
                      {proposal.quotes.find(
                        (quote) => quote.quotation.id == quoteEdit,
                      )?.quotation?.filter_stock &&
                        proposal.quotes.find(
                          (quote) => quote.quotation.id == quoteEdit,
                        )?.quotation?.filter_stock != '77sol' && (
                          <>
                            <Spacer size="8" direction="vertical" />
                            <EditIcon
                              onClick={() => {
                                setEditingValue(true)
                                const formatter = new Intl.NumberFormat(
                                  'pt-BR',
                                  {
                                    minimumFractionDigits: 2,
                                  },
                                )
                                setNewStockValue(
                                  formatter.format(
                                    parseFloat(
                                      actualQuote?.quotation.equipment_value,
                                    ),
                                  ),
                                )
                              }}
                              style={{
                                cursor: 'pointer',
                                minWidth: '20px',
                                minHeight: '20px',
                              }}
                              width="20"
                              color={palette.grayscale[700]}
                            />
                          </>
                        )}
                      {!hideAlert &&
                        handlePotency(
                          proposal.quotes.find(
                            (quote) => quote.quotation.id == quoteEdit,
                          )?.quotation.added_items
                            ? filterAddedItems(
                                proposal.quotes.find(
                                  (quote) => quote.quotation.id == quoteEdit,
                                )?.kit,
                                proposal.quotes.find(
                                  (quote) => quote.quotation.id == quoteEdit,
                                )?.quotation.added_items,
                              )
                            : proposal.quotes.find(
                                (quote) => quote.quotation.id == quoteEdit,
                              )?.kit,
                        ) !=
                          parseFloat(
                            proposal.sizings.find(
                              (item) =>
                                item.id ==
                                proposal.quotes.find(
                                  (quote) => quote.quotation.id == quoteEdit,
                                )?.dimensioning.id,
                            )?.potenciaCC / 1000,
                          )?.toFixed(2) && (
                          <Tooltip title="Potência desatualizada">
                            <>
                              <Spacer size="8" direction="vertical" />
                              <CircleActionsAlertInfoIcon
                                width="18"
                                color={palette.orange[300]}
                                style={{ cursor: 'pointer' }}
                                onClick={() => {
                                  setAlertQuotation(
                                    proposal.quotes.find(
                                      (quote) =>
                                        quote.quotation.id == quoteEdit,
                                    ),
                                  )
                                  setShowAlertDiferency(true)
                                }}
                              />
                            </>
                          </Tooltip>
                        )}
                    </div>
                  </div>
                )}
              </div>
              <Spacer size="10" direction="horizontal" />
              <div>
                <div style={{ display: 'flex', alignItems: 'baseline' }}>
                  {(!proposal.quotes.find(
                    (quote) => quote.quotation.id == quoteEdit,
                  )?.quotation?.filter_stock ||
                    proposal.quotes.find(
                      (quote) => quote.quotation.id == quoteEdit,
                    )?.quotation?.filter_stock == '77sol') && (
                    <Badge
                      size="large"
                      color="grayscale"
                      label={`Frete: ${
                        {
                          sem_frete: 'Sem frete',
                        }[actualQuote?.quotation?.delivery_state] ||
                        actualQuote?.quotation?.delivery_state
                      }`}
                    />
                  )}
                  {proposal.quotes.find(
                    (quote) => quote.quotation.id == quoteEdit,
                  )?.quotation?.filter_stock &&
                    proposal.quotes.find(
                      (quote) => quote.quotation.id == quoteEdit,
                    )?.quotation?.filter_stock != '77sol' && (
                      <Badge
                        size="large"
                        color="grayscale"
                        label={`Estoque: ${
                          proposal?.stocks?.stock?.find(
                            (stock) =>
                              stock.id ==
                              proposal.quotes.find(
                                (quote) => quote.quotation.id == quoteEdit,
                              )?.quotation?.filter_stock,
                          ).name
                        }`}
                      />
                    )}
                </div>
              </div>
            </Paper>
          </Grid>
          <Grid
            style={{ padding: '16px 0px' }}
            item
            xs={12}
            md={12}
            lg={12}
            xl={quotePanelEditing ? 12 : 12}
          >
            <Divider />
          </Grid>
          <Grid item xs={12} md={12} lg={12} xl={quotePanelEditing ? 12 : 12}>
            <Paper
              style={{
                height: 'auto',
                backgroundColor:
                  quotePanelEditing == 'budget' && palette.primary[300],
                borderRadius: '16px',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <Typography
                  type={
                    downSm ? 'link_small' : upXl ? 'link_medium' : 'text_small'
                  }
                  color={quotePanelEditing == 'budget' ? 'white' : 'grayscale'}
                  colorWeight="700"
                >
                  Orçamento
                </Typography>
                {quotePanelEditing == 'budget' ? (
                  <ArrowChevronForwardIcon width="16" color={palette.white} />
                ) : (
                  <div style={{ display: 'flex', gap: 10 }}>
                    {downSm ? (
                      <BudgetActions
                        icons
                        onEdit={() => {
                          setQuotePanelEditing('budget')
                          handleGetBudget(quoteEdit, 'budget')
                        }}
                      />
                    ) : (
                      <>
                        <Hidden xlUp>
                          <BudgetActions
                            icons
                            onEdit={() => {
                              setQuotePanelEditing('budget')
                              handleGetBudget(quoteEdit, 'budget')
                            }}
                          />
                        </Hidden>
                        <Hidden lgDown>
                          <BudgetActions
                            onEdit={() => {
                              setQuotePanelEditing('budget')
                              handleGetBudget(quoteEdit, 'budget')
                            }}
                          />
                        </Hidden>
                      </>
                    )}
                  </div>
                )}
              </div>
              <Spacer size={upXl ? '4' : '10'} direction="horizontal" />
              <div>
                <Typography
                  type="text_x_small"
                  color={quotePanelEditing == 'budget' ? 'white' : 'grayscale'}
                  colorWeight="600"
                >
                  Valor total do projeto
                </Typography>
                <Spacer size="10" direction="horizontal" />
                <div style={{ display: 'flex', alignItems: 'baseline' }}>
                  <Typography
                    type="text_x_small"
                    color={
                      quotePanelEditing == 'budget' ? 'white' : 'grayscale'
                    }
                    colorWeight="600"
                  >
                    R$
                  </Typography>
                  <Spacer size="4" direction="vertical" />
                  <Typography
                    type={
                      downSm
                        ? 'display_x_small_bold'
                        : upXl
                        ? 'display_small_bold'
                        : 'display_x_small_bold'
                    }
                    color={
                      quotePanelEditing == 'budget' ? 'white' : 'grayscale'
                    }
                    colorWeight="700"
                  >
                    {parseBRL(actualQuote?.quotation?.total_value).replace(
                      'R$',
                      '',
                    )}
                  </Typography>
                </div>
              </div>
            </Paper>
          </Grid>
          <Grid item xs={12} md={12} lg={12} xl={quotePanelEditing ? 12 : 12}>
            <Paper
              style={{
                height: '100%',
                backgroundColor:
                  quotePanelEditing == 'paymentsMethod' && palette.primary[300],
                borderRadius: '16px',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <Typography
                  type={
                    downSm ? 'link_small' : upXl ? 'link_medium' : 'text_small'
                  }
                  color={
                    quotePanelEditing == 'paymentsMethod'
                      ? 'white'
                      : 'grayscale'
                  }
                  colorWeight="700"
                >
                  Forma de pagamento
                </Typography>
                {quotePanelEditing == 'paymentsMethod' ? (
                  <ArrowChevronForwardIcon width="16" color={palette.white} />
                ) : downSm ? (
                  <Tooltip title="Editar">
                    <IconButton
                      id="quoteEdit_button_paymentsMethod"
                      aria-label="Editar"
                      onClick={() => setQuotePanelEditing('paymentsMethod')}
                    >
                      <EditIcon width="24px" color={palette.grayscale[700]} />
                    </IconButton>
                  </Tooltip>
                ) : (
                  <>
                    <Hidden xlUp>
                      <Tooltip title="Editar">
                        <IconButton
                          id="quoteEdit_button_paymentsMethod"
                          aria-label="Editar"
                          onClick={() => setQuotePanelEditing('paymentsMethod')}
                        >
                          <EditIcon
                            width="24px"
                            color={palette.grayscale[700]}
                          />
                        </IconButton>
                      </Tooltip>
                    </Hidden>
                    <Hidden lgDown>
                      <Button
                        id="quoteEdit_button_paymentsMethod"
                        onClick={() => setQuotePanelEditing('paymentsMethod')}
                        variant="outlined"
                        noMargin
                        size="small"
                      >
                        Editar
                      </Button>
                    </Hidden>
                  </>
                )}
              </div>
              <Spacer size={upXl ? '4' : '10'} direction="horizontal" />
              {methods?.bank_transfer ||
              methods?.billets ||
              methods?.cards ||
              methods?.financing.length ? (
                <>
                  <div>
                    {methods?.billets && (
                      <div>
                        <Typography
                          type={downSm || !upXl ? 'link_small' : 'text_medium'}
                          color={
                            quotePanelEditing == 'paymentsMethod'
                              ? 'white'
                              : 'primary'
                          }
                          colorWeight="300"
                        >
                          Boleto
                        </Typography>
                        <Typography
                          type={
                            downSm
                              ? 'link_x_small'
                              : upXl
                              ? 'text_small'
                              : 'text_x_small'
                          }
                          color={
                            quotePanelEditing == 'paymentsMethod'
                              ? 'primary'
                              : 'grayscale'
                          }
                          colorWeight={
                            quotePanelEditing == 'paymentsMethod'
                              ? '200'
                              : '600'
                          }
                        >
                          Pagamento em{' '}
                          {methods.billets.billet_four
                            ? '4'
                            : methods.billets.billet_three
                            ? '3'
                            : methods.billets.billet_two
                            ? '2'
                            : '1'}{' '}
                          boletos
                        </Typography>
                        <Spacer size="4" direction="horizontal" />
                      </div>
                    )}
                  </div>
                  <div>
                    {methods?.cards && (
                      <div>
                        <Typography
                          type={downSm || !upXl ? 'link_small' : 'text_medium'}
                          color={
                            quotePanelEditing == 'paymentsMethod'
                              ? 'white'
                              : 'primary'
                          }
                          colorWeight="300"
                        >
                          Cartão de crédito
                        </Typography>
                        <Typography
                          type={
                            downSm
                              ? 'link_x_small'
                              : upXl
                              ? 'text_small'
                              : 'text_x_small'
                          }
                          color={
                            quotePanelEditing == 'paymentsMethod'
                              ? 'primary'
                              : 'grayscale'
                          }
                          colorWeight={
                            quotePanelEditing == 'paymentsMethod'
                              ? '200'
                              : '600'
                          }
                        >
                          Pagamento em até {methods.cards.number_installments}x
                        </Typography>
                        <Spacer size="4" direction="horizontal" />
                      </div>
                    )}
                  </div>
                  <div>
                    {methods?.financing.length != 0 && (
                      <div>
                        <Typography
                          type={downSm || !upXl ? 'link_small' : 'text_medium'}
                          color={
                            quotePanelEditing == 'paymentsMethod'
                              ? 'white'
                              : 'primary'
                          }
                          colorWeight="300"
                        >
                          Calculadora de financiamentos
                        </Typography>
                        <Typography
                          type={
                            downSm
                              ? 'link_x_small'
                              : upXl
                              ? 'text_small'
                              : 'text_x_small'
                          }
                          color={
                            quotePanelEditing == 'paymentsMethod'
                              ? 'primary'
                              : 'grayscale'
                          }
                          colorWeight={
                            quotePanelEditing == 'paymentsMethod'
                              ? '200'
                              : '600'
                          }
                        >
                          Simulação em {methods.financing.length} bancos
                        </Typography>
                        <Spacer size="4" direction="horizontal" />
                      </div>
                    )}
                  </div>
                  <div>
                    {methods?.bank_transfer && (
                      <Typography
                        type={downSm || !upXl ? 'link_small' : 'text_medium'}
                        color={
                          quotePanelEditing == 'paymentsMethod'
                            ? 'white'
                            : 'primary'
                        }
                        colorWeight="300"
                      >
                        Transferência bancária
                      </Typography>
                    )}
                  </div>
                </>
              ) : (
                <>
                  <Spacer size="10" direction="horizontal" />
                  <Typography
                    type={
                      downSm
                        ? 'link_x_small'
                        : upXl
                        ? 'text_small'
                        : 'text_x_small'
                    }
                    color={
                      quotePanelEditing == 'paymentsMethod'
                        ? 'white'
                        : 'grayscale'
                    }
                    colorWeight="500"
                  >
                    Nenhuma forma de pagamento selecionada.
                  </Typography>
                </>
              )}
            </Paper>
          </Grid>
          {returnData?.dadosConsolidados && (
            <Grid item xs={12} md={12}>
              <InvestimentoRetornoCard
                returnData={returnData}
                quotePanelEditing={quotePanelEditing}
                setQuotePanelEditing={setQuotePanelEditing}
              />
            </Grid>
          )}
        </Grid>
        <Spacer size="48" direction="horizontal" />
      </div>
      <div
        style={{
          padding: '24px 32px',
          width:
            (downSm || (isFromProposalList && !upXl)) && quotePanelEditing
              ? '100%'
              : quotePanelEditing
              ? upXl
                ? isFromProposalList
                  ? '468px'
                  : '652px'
                : '388px'
              : '0%',
          borderLeft: '1px solid rgb(226, 230, 231)',
          display: quotePanelEditing ? 'flex' : 'none',
          transition: 'width 0.3s ease-in-out 0s',
          height: isFromProposalList ? 'calc(100% - 36px)' : '100%',
          overflowY: 'auto',
        }}
      >
        {quotePanelEditing == 'budget' && (
          <>
            <OrcamentoPanel
              isFromProposalList={isFromProposalList}
              handleShowRoi={handleShowRoi}
              handleGetBudget={handleGetBudget}
              budgetEdit={budgetEdit}
              selectedQuote={selectedQuote}
            />
            <Spacer size="48" direction="horizontal" />
          </>
        )}
        {quotePanelEditing == 'equipments' &&
          (openConfigsEquipments ? (
            <div style={{ width: '100%' }}>
              {productsCategories.map((categoria, i) => (
                <>
                  <Paper
                    style={{
                      padding:
                        !upXl || isFromProposalList ? '8px 16px' : '12px 32px',
                      marginTop: i != 0 && '8px',
                      height: 'auto',
                    }}
                  >
                    <Grid
                      container
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'center',
                        alignItems: 'center',
                        width: '100%',
                      }}
                    >
                      <Grid item xs={3} xl={isFromProposalList ? 3 : 2}>
                        <Toggle
                          onClick={(event) => event.stopPropagation()}
                          checked={
                            productsConfigs.find(
                              (product) => product.category_id == categoria.id,
                            )?.status
                          }
                          disabled={
                            !productsConfigs.find(
                              (product) => product.category_id == categoria.id,
                            )?.title
                          }
                          onChange={() => {
                            if (
                              productsConfigs.find(
                                (product) =>
                                  product.category_id == categoria.id,
                              )?.status == 0
                            ) {
                              const index = productsConfigs.findIndex(
                                (product) =>
                                  product.category_id == categoria.id,
                              )
                              const newArray = productsConfigs
                              newArray[index].status = true
                              setProductsConfigs([...newArray])
                              handleUpdateProductLabel(
                                [...newArray].find(
                                  (product) =>
                                    product.category_id == categoria.id,
                                )?.id,
                                productsConfigs.find(
                                  (product) =>
                                    product.category_id == categoria.id,
                                )?.title
                                  ? productsConfigs.find(
                                      (product) =>
                                        product.category_id == categoria.id,
                                    )?.title
                                  : categoria.nome,
                                productsConfigs.find(
                                  (product) =>
                                    product.category_id == categoria.id,
                                )?.status,
                                categoria.id,
                                categoria.nome,
                              )
                            } else {
                              const index = productsConfigs.findIndex(
                                (product) =>
                                  product.category_id == categoria.id,
                              )
                              const newArray = productsConfigs
                              newArray[index].status = false
                              setProductsConfigs([...newArray])
                              handleUpdateProductLabel(
                                [...newArray].find(
                                  (product) =>
                                    product.category_id == categoria.id,
                                )?.id,
                                productsConfigs.find(
                                  (product) =>
                                    product.category_id == categoria.id,
                                )?.title
                                  ? productsConfigs.find(
                                      (product) =>
                                        product.category_id == categoria.id,
                                    )?.title
                                  : categoria.nome,
                                productsConfigs.find(
                                  (product) =>
                                    product.category_id == categoria.id,
                                )?.status,
                                categoria.id,
                                categoria.nome,
                              )
                            }
                          }}
                        />
                      </Grid>
                      <Grid
                        item
                        xs={8}
                        sm={8}
                        md={8}
                        xl={isFromProposalList ? 8 : 4}
                      >
                        <Typography
                          type={
                            !upXl || isFromProposalList
                              ? 'text_small'
                              : 'text_medium'
                          }
                          color="grayscale"
                          colorWeight="600"
                        >
                          {!upXl || isFromProposalList ? (
                            productsConfigs.find(
                              (product) => product.category_id == categoria.id,
                            )?.title ? (
                              productsConfigs.find(
                                (product) =>
                                  product.category_id == categoria.id,
                              )?.title
                            ) : (
                              categoria.nome
                            )
                          ) : (
                            <>{categoria.nome}</>
                          )}
                        </Typography>
                        {(!upXl || isFromProposalList) &&
                          productsConfigs.find(
                            (product) => product.category_id == categoria.id,
                          )?.title && (
                            <Typography
                              type="text_x_small"
                              color="grayscale"
                              colorWeight="400"
                            >
                              {categoria.nome}
                            </Typography>
                          )}
                      </Grid>
                      {!(!upXl || isFromProposalList) && (
                        <Grid
                          style={{ display: 'flex', alignItems: 'center' }}
                          item
                          xs={4}
                          xl={isFromProposalList ? 4 : 6}
                        >
                          {actualProductEditing == categoria.id ? (
                            <>
                              <CircleActionsCloseIcon
                                width="24"
                                color={palette.grayscale[700]}
                                style={{ cursor: 'pointer' }}
                                onClick={() => {
                                  setActualProductEditing('')
                                  setNewTitleProduct('')
                                }}
                              />
                              <Spacer size="16" direction="vertical" />
                              <Input
                                size="small"
                                InputLabelProps={{ shrink: true }}
                                value={newTitleProduct}
                                setValue={setNewTitleProduct}
                                label={`Titulo de ${categoria.nome}`}
                                defaultValue={
                                  categoria.descricao
                                    ? categoria.descricao
                                    : categoria.nome
                                }
                              />
                              <Spacer size="16" direction="vertical" />
                              <Button
                                size="small"
                                noMargin
                                disabled={!newTitleProduct}
                                isLoading={loadingChangeLabel}
                                onClick={() => {
                                  handleUpdateProductLabel(
                                    productsConfigs.find(
                                      (product) =>
                                        product.category_id == categoria?.id,
                                    )?.id,
                                    newTitleProduct,
                                    false,
                                    categoria?.id,
                                    categoria?.nome,
                                  )
                                }}
                              >
                                Salvar
                              </Button>
                            </>
                          ) : (
                            <>
                              <EditIcon
                                width="24"
                                color={palette.grayscale[700]}
                                style={{ cursor: 'pointer' }}
                                onClick={() =>
                                  setActualProductEditing(categoria.id)
                                }
                              />
                              <Spacer size="16" direction="vertical" />
                              <Typography
                                type="text_medium"
                                color="grayscale"
                                colorWeight="600"
                              >
                                {productsConfigs.find(
                                  (product) =>
                                    product.category_id == categoria.id,
                                )?.title
                                  ? productsConfigs.find(
                                      (product) =>
                                        product.category_id == categoria.id,
                                    )?.title
                                  : categoria.nome}
                              </Typography>
                            </>
                          )}
                        </Grid>
                      )}
                      {!(!upXl || isFromProposalList) && <Grid item xs={2} />}
                      {(!upXl || isFromProposalList) &&
                        actualProductEditing == categoria.id && (
                          <Grid item xs={12}>
                            <Input
                              style={{ marginTop: '16px' }}
                              size="small"
                              InputLabelProps={{ shrink: true }}
                              value={newTitleProduct}
                              setValue={setNewTitleProduct}
                              label={`Titulo de ${categoria.nome}`}
                              defaultValue={
                                categoria.descricao
                                  ? categoria.descricao
                                  : categoria.nome
                              }
                              fullWidth
                            />
                            <div
                              style={{
                                display: 'flex',
                                justifyContent: 'flex-end',
                                marginTop: '16px',
                              }}
                            >
                              <Button
                                size="small"
                                noMargin
                                disabled={!newTitleProduct}
                                isLoading={loadingChangeLabel}
                                onClick={() => {
                                  handleUpdateProductLabel(
                                    productsConfigs.find(
                                      (product) =>
                                        product.category_id == categoria?.id,
                                    )?.id,
                                    newTitleProduct,
                                    false,
                                    categoria?.id,
                                    categoria?.nome,
                                  )
                                }}
                              >
                                Salvar
                              </Button>
                              <Spacer size="16" direction="vertical" />
                              <Button
                                size="small"
                                variant="outlined"
                                noMargin
                                color="red"
                                onClick={() => {
                                  setActualProductEditing('')
                                }}
                              >
                                Cancelar
                              </Button>
                            </div>
                          </Grid>
                        )}
                      {(!upXl || isFromProposalList) &&
                        actualProductEditing != categoria.id && (
                          <Grid item xs={1}>
                            <EditIcon
                              width="16"
                              color={palette.grayscale[700]}
                              onClick={() =>
                                setActualProductEditing(categoria.id)
                              }
                              style={{ cursor: 'pointer' }}
                            />
                          </Grid>
                        )}
                    </Grid>
                  </Paper>
                  {productsCategories.length == i + 1 && (
                    <Spacer size="48" direction="horizontal" />
                  )}
                </>
              ))}
            </div>
          ) : (
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  height: 'auto',
                  flexDirection: (!upXl || isFromProposalList) && 'column',
                }}
              >
                <Button
                  onClick={() => {
                    setCompleteQuoteEdit(true)
                    dispatch(
                      ProposalAction.SaveActiveSizingRedux(
                        proposal.quotes.find(
                          (quote) => quote.quotation.id == quoteEdit,
                        ).dimensioning.id,
                      ),
                    )
                    setQuoteIdEditing(quoteEdit)
                  }}
                  size="small"
                  variant="outlined"
                  fullWidth
                >
                  Edição completa
                </Button>
                <Spacer
                  size={!upXl || isFromProposalList ? '8' : '16'}
                  direction={
                    !upXl || isFromProposalList ? 'horizontal' : 'vertical'
                  }
                />
                <Button
                  onClick={() => setOpenConfigsEquipments(true)}
                  size="small"
                  variant="outlined"
                  fullWidth
                >
                  Configurar produtos
                </Button>
              </div>
              <Spacer
                style={{ minHeight: '16px' }}
                size="16"
                direction="horizontal"
              />
              <EquipmentsList
                isFromProposalList={isFromProposalList}
                quoteEdit={actualQuote}
              />
            </div>
          ))}
        {quotePanelEditing == 'paymentsMethod' && (
          <div style={{ width: '100%' }}>
            <PagamentoEdit
              isFromProposalList={isFromProposalList}
              proposal={proposal}
              selectedQuoteEdit={quoteEdit}
              setMethodsQuoteEdit={setMethods}
            />
            <Spacer size="48" direction="horizontal" />
          </div>
        )}
        {quotePanelEditing == 'investmentReturn' && (
          <Grid container spacing={2}>
            <Grid xs={12} md={12} item>
              <ConcessionariaCard
                isFromProposalList={isFromProposalList}
                proposal={proposal}
              />
            </Grid>
            {returnData?.dadosConsolidados && (
              <Grid xs={12} md={12} item>
                <TarifaCard
                  isFromProposalList={isFromProposalList}
                  selectedQuote={quoteEdit}
                  setReturnData={setReturnData}
                  returnData={returnData}
                />
              </Grid>
            )}
            {returnData?.dadosConsolidados && (
              <Grid xs={12} md={12} item>
                <CorrecaoAnualCard
                  isFromProposalList={isFromProposalList}
                  selectedQuote={quoteEdit}
                  setReturnData={setReturnData}
                  returnData={returnData}
                />
              </Grid>
            )}
            {returnData?.dadosConsolidados && (
              <Grid xs={12} md={12} item>
                <InvestimentoRetornoCard
                  isFromProposalList={isFromProposalList}
                  returnData={returnData}
                  quotePanelEditing={quotePanelEditing}
                  setQuotePanelEditing={setQuotePanelEditing}
                  onlyShow
                />
                <Spacer size="48" direction="horizontal" />
              </Grid>
            )}
          </Grid>
        )}
      </div>
      <Modal
        className={classes.modalSm}
        open={modalRefreshBudget}
        onClose={() => {
          setModalRefreshBudget(false)
        }}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={modalRefreshBudget} style={{ border: 'none' }}>
          <Paper style={{ height: 'auto', width: 'auto' }}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <RefreshIcon
                width="40"
                color={palette.primary[300]}
                style={{ minWidth: '40px', minHeight: '40px' }}
              />
              <Spacer size="16" direction="vertical" />
              <Typography
                type="display_x_small_bold"
                color="primary"
                colorWeight="300"
              >
                Deseja atualizar o orçamento de acordo as suas
                <br /> configurações de precificação?
              </Typography>
            </div>
            <Spacer size="24" direction="horizontal" />
            <div
              style={{ width: '100%', display: 'flex', alignItems: 'center' }}
            >
              <Button
                onClick={() => {
                  handleAttPrecification()
                }}
                isLoading={loadingAttPrecification}
                size="small"
                startIcon={<RefreshIcon />}
              >
                Atualizar
              </Button>
              <Spacer size="8" direction="both" />
              <Button
                onClick={() => {
                  setModalRefreshBudget(false)
                }}
                size="small"
                variant="outlined"
                style={{ padding: '15px 22px' }}
              >
                Cancelar
              </Button>
            </div>
          </Paper>
        </Fade>
      </Modal>
      <ModalSizingAlert
        showAlertDiferency={showAlertDiferency}
        setShowAlertDiferency={setShowAlertDiferency}
        alertQuotation={alertQuotation}
        proposal={proposal}
        loadingUpdateKitPotency={loadingUpdateKitPotency}
        loadingChangePotency={loadingChangePotency}
        setHideAlert={setHideAlert}
        handleUpdateKitPotency={handleUpdateKitPotency}
        handleChangePotency={handleChangePotency}
      />
    </div>
  )
}

export default QuoteEdit
