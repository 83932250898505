import { Button } from '@77sol-ui/atoms'
import { ChevronRight } from 'lucide-react'
import { useCompareKitFooter } from './hooks/useCompareKitFooter'
import { ButtonNotifyIntegrator } from 'containers/EquipmentQuotationContainer/components/ButtonNotifyIntegrator'
import { type IProposalIntegrator } from 'dto/ProposalDTO'
import { useCreateProject } from 'containers/EquipmentQuotationContainer/hooks/useCreateProject'
import { useAmplitude } from 'hooks/useAmplitude'
import { TAB_ENUM_AMPLITUDE } from 'containers/EquipmentQuotationContainer/enums/tabEnum'
import { bottomMenuTracker } from 'services/tracker/events/bottomMenu/trackers'
import { useDisableActionButtons } from 'containers/EquipmentQuotationContainer/hooks/useDisableActionButtons'
import { useUserHasFullRegistration } from 'hooks/useUserHasFullRegistration'

export function CompareKitFooter() {
  const {
    canShowNotifyIntegratorButton,
    integrator,
    canShowCreateProjectButton,
  } = useCompareKitFooter()
  const { logEvent } = useAmplitude()

  const { handleCreateProject: createProject } = useCreateProject()
  const { validateUserRegistration } = useUserHasFullRegistration()
  const { disabled } = useDisableActionButtons()

  const handleCreateProject = () => {
    if (!validateUserRegistration()) return

    logEvent(bottomMenuTracker.actions.createProject, {
      origin: '/cotar',
      tab: TAB_ENUM_AMPLITUDE['compare-kit'],
    })
    createProject()
  }

  return (
    <>
      <div className="info">
        <h1>Você está em modo comparação</h1>
        <p>
          Reserve os equipamentos da sua cotação de preferência ou crie um
          projeto com suas cotações.
        </p>
      </div>

      <div className="actions">
        {canShowCreateProjectButton && (
          <Button disabled={disabled} onClick={handleCreateProject}>
            <ChevronRight />
            <span>Criar Projeto</span>
          </Button>
        )}

        {canShowNotifyIntegratorButton && (
          <ButtonNotifyIntegrator
            integrator={integrator as IProposalIntegrator}
          />
        )}
      </div>
    </>
  )
}
