import { Badge } from '@77sol/core'
import { Button } from '@77sol-ui/atoms'
import { ChevronRight } from 'lucide-react'
import { RoomServiceHelpIcon } from '@77sol/icons/dist'
import * as S from './styles'
import { useCustomizeKitFooter } from './hooks/useCustomizeKitFooter'
import { ButtonNotifyIntegrator } from 'containers/EquipmentQuotationContainer/components/ButtonNotifyIntegrator'
import { type IProposalIntegrator } from 'dto/ProposalDTO'
import { useAmplitude } from 'hooks/useAmplitude'
import { bottomMenuTracker } from 'services/tracker/events/bottomMenu/trackers'
import { useReserveEquipments } from '../../../../../../hooks/useReserveEquipments'
import { TAB_ENUM_AMPLITUDE } from 'containers/EquipmentQuotationContainer/enums/tabEnum'
import { useEquipmentContainerStore } from 'containers/EquipmentQuotationContainer/store/EquipmentQuotationStore'
import { useCreateProject } from 'containers/EquipmentQuotationContainer/hooks/useCreateProject'
import { useDisableActionButtons } from 'containers/EquipmentQuotationContainer/hooks/useDisableActionButtons'
import { useValidateKit } from 'containers/EquipmentQuotationContainer/hooks/useValidateKit'
import { useUserHasFullRegistration } from 'hooks/useUserHasFullRegistration'

export function CustomizeKitFooter() {
  const { handleCreateProject: createProject } = useCreateProject()
  const { disabled } = useDisableActionButtons()
  const {
    quotationTotalValue,
    integrator,
    quotationCurrentDiscountPlan,
    canShowReserveEquipmentsButton,
    canShowCreateProjectButton,
    canShowNotifyIntegratorButton,
  } = useCustomizeKitFooter()

  const { validateUserRegistration } = useUserHasFullRegistration()
  const { checkIfQuotationHasChanged } = useValidateKit()

  const quotationSelected = useEquipmentContainerStore(
    (state) => state.quotationSelected,
  )

  const setContinueQuotationWithChangesCallback = useEquipmentContainerStore(
    (state) => state.setContinueQuotationWithChangesCallback,
  )

  const setOpenModalContinueWithChanged = useEquipmentContainerStore(
    (state) => state.setOpenModalContinueWithChanged,
  )

  const { logEvent } = useAmplitude()

  const { handleReserveEquipments: reserveEquipments } = useReserveEquipments()

  const handleCreateProject = () => {
    if (!validateUserRegistration()) return

    logEvent(bottomMenuTracker.actions.createProject, {
      origin: '/cotar',
      tab: TAB_ENUM_AMPLITUDE['customize-kit'],
    })
    createProject()
  }

  function checkIfCanReserve() {
    if (!validateUserRegistration()) return

    const hasItensChangedOrWithError = checkIfQuotationHasChanged()

    if (!hasItensChangedOrWithError) {
      handleReserveEquipments()
      return
    }

    setContinueQuotationWithChangesCallback(() => {
      handleReserveEquipments()
    })
    setOpenModalContinueWithChanged(true)
  }

  const handleReserveEquipments = () => {
    if (!quotationSelected) return
    reserveEquipments(quotationSelected?.id)
    logEvent(bottomMenuTracker.actions.equipmentBooking, {
      origin: '/cotar',
      tab: TAB_ENUM_AMPLITUDE['customize-kit'],
    })
  }

  return (
    <>
      <S.QuotationTotal>
        <h1>
          Valor total: <span>{quotationTotalValue}</span>
        </h1>
        <p>
          Desconto:{' '}
          <Badge
            label={quotationCurrentDiscountPlan.label}
            color={quotationCurrentDiscountPlan.color}
            colorWeight={quotationCurrentDiscountPlan.weight}
          />
        </p>
      </S.QuotationTotal>

      <div className="actions">
        {canShowReserveEquipmentsButton && (
          <Button
            variant="outline"
            onClick={checkIfCanReserve}
            disabled={disabled}
          >
            <RoomServiceHelpIcon />
            <span>Reservar Equipamentos</span>
          </Button>
        )}
        {canShowCreateProjectButton && (
          <Button onClick={handleCreateProject} disabled={disabled}>
            <ChevronRight />
            <span>Criar Projeto</span>
          </Button>
        )}
        {canShowNotifyIntegratorButton && (
          <ButtonNotifyIntegrator
            integrator={integrator as IProposalIntegrator}
          />
        )}
      </div>
    </>
  )
}
