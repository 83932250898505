import { Input, InputMask } from '@77sol-ui/atoms'
import { useCreateFinancingPerQuotationFormContext } from '../../hooks/useCreateFinancingPerQuotationForm'
import { MaskedMonetaryInput } from 'components/Controllers/MaskedMonetaryInput'
import * as S from './styles'

export function JuridicalPerson() {
  const {
    register,
    control,
    formState: { errors },
    getValues,
  } = useCreateFinancingPerQuotationFormContext()

  return (
    <section>
      <Input
        label="Nome do Solicitante"
        placeholder="Digite a razão social"
        type="text"
        error={errors.client_name?.message}
        {...register('client_name')}
      />
      <S.InputGroup>
        <InputMask.Masked
          label="Data de Fundação"
          mask="99/99/9999"
          placeholder="DD/MM/AAAA"
          type="text"
          error={errors.client_birth_date?.message}
          {...register('client_birth_date')}
          defaultValue={getValues('client_birth_date') as string}
        />
        <InputMask.Masked
          label="CNPJ"
          mask="99.999.999/9999-99"
          placeholder="Digite o CNPJ"
          type="text"
          error={errors.client_document?.message}
          {...register('client_document')}
          defaultValue={getValues('client_document')}
        />
      </S.InputGroup>
      <S.InputGroup>
        <MaskedMonetaryInput
          label="Faturamento Anual"
          placeholder="R$0,00"
          error={errors.client_income?.message}
          control={control}
          name="client_income"
        />
      </S.InputGroup>
    </section>
  )
}
