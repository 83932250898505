import { type IKitDTOWithId } from 'containers/EquipmentQuotationContainer/hooks/useLoadKits'
import { isBefore } from 'date-fns'

const MONTH = 10 // NOVEMBER
const DAY = 30 // END DAY
const YEAR = 2024
const HOUR = 23
const MINUTE = 59
const SECOND = 59

const ELIGIBLE_SUPPLIERS = {
  JNG: 'JNG',
  VENDEMMIA: 'Vendemmia',
  ADIAS: 'Adias',
}

export const useBlackFridayFlag = (kit: IKitDTOWithId) => {
  const now = new Date()
  const targetDate = new Date(YEAR, MONTH, DAY, HOUR, MINUTE, SECOND)

  const isBlackFriday = isBefore(now, targetDate)

  const suppliers =
    kit.delivered_by === ELIGIBLE_SUPPLIERS.JNG ||
    kit.delivered_by === ELIGIBLE_SUPPLIERS.VENDEMMIA ||
    kit.delivered_by === ELIGIBLE_SUPPLIERS.ADIAS

  const canShowBlackFriday = isBlackFriday && suppliers

  return {
    canShowBlackFriday,
  }
}
