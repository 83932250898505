import { useSelector } from 'react-redux'
import { useCallback } from 'react'
import { useCompleteRegistrationModalContext } from 'context/CompleteRegistrationModalContext'

export const useUserHasFullRegistration = () => {
  const {
    isOpenCompleteRegistrationModal,
    setIsOpenCompleteRegistrationModal,
    closeModalCompleteUserData,
  } = useCompleteRegistrationModalContext()

  const isCompleted = useSelector(
    (state: any) => state?.ReducerAuth?.userLogged?.[0]?.is_completed,
  )

  const validateUserRegistration = useCallback(() => {
    if (!isCompleted) {
      setIsOpenCompleteRegistrationModal(true)
      return false
    }
    return true
  }, [isCompleted])

  return {
    setIsOpenCompleteRegistrationModal,
    validateUserRegistration,
    isOpenCompleteRegistrationModal,
    closeModalCompleteUserData,
    isCompleted,
  }
}
