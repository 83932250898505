import { type Resolver, useForm } from 'react-hook-form'

import { yupResolver } from '@hookform/resolvers/yup'
import { useSelector } from 'react-redux'
import { useCallback, useEffect, useMemo } from 'react'
import { type IQuotationFormProps, type IQuotationFormData } from '../types'
import { adminSchema, baseSchema } from '../validators/formValidator'
import { FORCE_ACTIVATE_USER_DEFAULT_VALUES } from '../constants'

export function useQuotationForm({
  defaultValues,
}: Pick<IQuotationFormProps, 'defaultValues'>) {
  const userProfile = useSelector(
    (state: {
      ReducerProfile: {
        userProfile: {
          role_id: number
          privilegios_admin: number
          uf: string
        }
      }
    }) => state.ReducerProfile.userProfile,
  )

  const operationalUser = localStorage?.getItem?.('sessionDataId') === '4282'
  const isAdmin =
    userProfile.role_id === 1 || userProfile.privilegios_admin === 1

  const schema = useMemo(() => (isAdmin ? adminSchema : baseSchema), [isAdmin])

  const newUser = sessionStorage.getItem('@77sol:new_user')

  const formDefaultValues = Boolean(newUser)
    ? {
        ...FORCE_ACTIVATE_USER_DEFAULT_VALUES,
        delivery_state:
          defaultValues?.delivery_state ||
          userProfile?.uf ||
          FORCE_ACTIVATE_USER_DEFAULT_VALUES.delivery_state,
        type_test: defaultValues?.type_test || 'old',
      }
    : {
        ...defaultValues,
        delivery_state: defaultValues?.delivery_state || userProfile?.uf,
        type_test: defaultValues?.type_test || 'old',
      }

  const {
    register,
    handleSubmit,
    control,
    watch,
    setValue,
    formState: { errors },
    setFocus,
  } = useForm<IQuotationFormData>({
    resolver: yupResolver(schema) as Resolver<IQuotationFormData>,
    defaultValues: formDefaultValues,
  })

  const selectedPhase = watch('power_fase')

  const handleClearVoltageField = useCallback(() => {
    setValue('voltage', '')
  }, [])

  const handleUpdateDeliveryState = useCallback((delivery_state = '') => {
    if (delivery_state) {
      setValue('delivery_state', delivery_state, {
        shouldValidate: true,
      })
    }
  }, [])

  const handleAdjustDiscount = useCallback((value: string) => {
    const discountValue = Number(value)

    if (value.length <= 1) {
      setValue('discount', value)
    } else if (discountValue && discountValue < 48) {
      setValue('discount', '48')
    } else if (discountValue && discountValue >= 50.4) {
      setValue('discount', '50.4')
    } else {
      setValue('discount', value)
    }
  }, [])

  const handleAdjustDiscountFocus = useCallback((value: string) => {
    const discountValue = Number(value)

    if (discountValue) {
      if (discountValue < 48) {
        setValue('discount', '48')
      } else if (discountValue >= 50.4) {
        setValue('discount', '50.4')
      }
    } else {
      setValue('discount', value)
    }
  }, [])

  useEffect(() => {
    const autoFocusTimeout = setTimeout(() => {
      setFocus('system_potency')
    }, 50)

    return () => {
      clearTimeout(autoFocusTimeout)
    }
  }, [])

  return {
    register,
    handleSubmit,
    control,
    watch,
    setValue,
    errors,
    selectedPhase,
    isAdmin,
    operationalUser,
    handleUpdateDeliveryState,
    handleClearVoltageField,
    handleAdjustDiscount,
    handleAdjustDiscountFocus,
  }
}
