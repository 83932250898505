import React from 'react'
import * as S from './styles'
import { type IQuotationProductDTO } from 'dto/QuotationProductDTO'

interface IProductListProps {
  products: IQuotationProductDTO[]
  addedProducts: IQuotationProductDTO[]
}
const ProductList: React.FC<IProductListProps> = ({
  products = [],
  addedProducts = [],
}) => {
  const lastProductIndex = products.length - 1
  const lastAddedProductIndex = addedProducts.length - 1

  return (
    <S.ProductListWrapper>
      <div>
        <S.Title>Itens do Kit</S.Title>
        {products.map((product, index) => (
          <S.ProductWrapper
            className="productContainer"
            key={product.id}
            noDivider={index === lastProductIndex}
          >
            <img src={product?.url} alt={product?.titulo} />
            <span className="productTitle">{product?.titulo}</span>
            <span>
              <span className="quantity">{product?.qtde}</span> <span>un</span>
            </span>
          </S.ProductWrapper>
        ))}
      </div>

      {addedProducts.length > 0 && (
        <div>
          <S.Title>Itens extras</S.Title>
          {addedProducts.map((product, index) => (
            <S.ProductWrapper
              className="productContainer"
              key={product.id}
              noDivider={index === lastAddedProductIndex}
            >
              <img src={product?.url} alt={product?.titulo} />
              <span className="productTitle">{product?.titulo}</span>
              <span>
                <span className="quantity">{product?.qtde}</span>{' '}
                <span>un</span>
              </span>
            </S.ProductWrapper>
          ))}
        </div>
      )}
    </S.ProductListWrapper>
  )
}

export { ProductList }
