import { Button, useToast } from '@77sol/core'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import * as S from './styles'
import { useAmplitude } from 'hooks/useAmplitude'
import { financingTracker } from 'services/tracker/events/financing/trackers'
import { useUserHasFullRegistration } from 'hooks/useUserHasFullRegistration'

export function ProceedToApproval({ selectedFinancing }) {
  const { logEvent } = useAmplitude()
  const { onSuccessOpenToast } = useToast()
  const { validateUserRegistration, isCompleted } = useUserHasFullRegistration()

  const handleOpenLink = () => {
    if (!validateUserRegistration()) return

    logEvent(financingTracker.actions.openFormalizationLink, {
      origin: '/financiamentos',
    })

    window.open(selectedFinancing?.link || '/#$', '_blank')
  }

  const handleCopyLink = () => {
    if (!validateUserRegistration()) return

    logEvent(financingTracker.actions.copyFormalizationLink, {
      origin: '/financiamentos',
    })

    onSuccessOpenToast('Link copiado!')
  }

  return (
    <S.Container>
      <Button size="small" variant="outlined" onClick={handleOpenLink}>
        Preencher dados
      </Button>
      <CopyToClipboard text={!isCompleted ? '' : selectedFinancing?.link}>
        <Button
          size="small"
          variant="outlined"
          onClick={() => handleCopyLink()}
        >
          Enviar Link
        </Button>
      </CopyToClipboard>
    </S.Container>
  )
}
