export const pageLoadedTrackers = [
  {
    key: 'Página: Início',
    pathName: '/',
  },
  {
    key: 'Página: Calendario',
    pathName: '/calendario',
  },
  {
    key: 'Página: Projetos',
    pathName: '/projetos',
  },
  {
    key: 'Página: Financiamentos',
    pathName: '/financiamentos',
  },
  {
    key: 'Página: Equipamentos',
    pathName: '/equipamentos',
  },
  {
    key: 'Página: Pedidos',
    pathName: '/pedidos',
  },
  {
    key: 'Página: Pagamentos',
    pathName: '/carteira/pagamentos',
  },
  {
    key: 'Página: Configurações',
    pathName: '/configuracoes/empresa',
  },
  {
    key: 'Página: Meu Time',
    pathName: '/time',
  },
  {
    key: 'Página: Ranking de Integradores',
    pathName: '/rank-77',
  },
]
