import { GET_ME } from '../constants/endpointKeys'
import api from 'api'
import { type IMeResponse } from './types'

export const service = {
  get: async () => {
    const { data } = await api.get<IMeResponse>(GET_ME)
    return data
  },
}
