import { useAuthMe } from 'domains/auth/me'
import { useOpenQuantionModal } from 'domains/auth/open-quotation-modal'
import { useUserHasFullRegistration } from 'hooks/useUserHasFullRegistration'
import { useHistory, useLocation } from 'react-router-dom'

export const useCancelQuotationContainer = () => {
  const { search = '' } = useLocation()
  const history = useHistory()
  const { isCompleted } = useUserHasFullRegistration()
  const { data: authMeResponse } = useAuthMe()
  const { mutate } = useOpenQuantionModal()

  const removeQuotationModalFromHome =
    isCompleted && authMeResponse?.[0].open_quotation_modal

  const handleCancelQuotationRequest = () => {
    if (search.includes('quotationModal')) history.push('/?fromModal')
    if (removeQuotationModalFromHome) mutate()
  }
  return { handleCancelQuotationRequest }
}
