import { service } from '../services/api'
import { useMutation } from '@tanstack/react-query'

export const useOpenQuantionModal = () => {
  const mutation = useMutation({
    mutationFn: async () => {
      return await service.post()
    },
  })

  return mutation
}
