import { type IBannerImage } from '..'

import { BannersTrackers } from 'services/tracker/events/Banners'

export const CAROUSEL_DEFAULT_CONFIGS = {
  key: 'autoplay-slide',
  autoplay: true,
  autoplaySpeed: 10000,
  infinite: true,
  speed: 500,
  centerMode: true,
}

export const CARROUSEL_CONTENT: IBannerImage[] = [
  {
    image: `https://77solassets.s3.sa-east-1.amazonaws.com/banners/${process.env.REACT_APP_S3_PREFIX}/home/banner_1.png`,
    mobileImage: `https://77solassets.s3.sa-east-1.amazonaws.com/banners/${process.env.REACT_APP_S3_PREFIX}/home/banner_1_mobile.png`,
    largeImage: `https://77solassets.s3.sa-east-1.amazonaws.com/banners/${process.env.REACT_APP_S3_PREFIX}/home/banner_1_large.png`,
    url: 'https://docs.google.com/forms/d/e/1FAIpQLSdQYZVDPicoDiKYdWRw0By2_Butc5n-f96eKADv5XWemaOGlw/viewform',
    event: BannersTrackers.actions.clickOnBanner,
    bannerName: 'SolLeads: Comece o ano bem!',
    target: '_blank',
  },
  {
    image: `https://77solassets.s3.sa-east-1.amazonaws.com/banners/${process.env.REACT_APP_S3_PREFIX}/home/banner_2.png`,
    mobileImage: `https://77solassets.s3.sa-east-1.amazonaws.com/banners/${process.env.REACT_APP_S3_PREFIX}/home/banner_2_mobile.png`,
    largeImage: `https://77solassets.s3.sa-east-1.amazonaws.com/banners/${process.env.REACT_APP_S3_PREFIX}/home/banner_2_large.png`,
    url: '',
    event: '',
    bannerName: '',
    target: '',
  },
  {
    image: `https://77solassets.s3.sa-east-1.amazonaws.com/banners/${process.env.REACT_APP_S3_PREFIX}/home/banner_3.png`,
    mobileImage: `https://77solassets.s3.sa-east-1.amazonaws.com/banners/${process.env.REACT_APP_S3_PREFIX}/home/banner_3_mobile.png`,
    largeImage: `https://77solassets.s3.sa-east-1.amazonaws.com/banners/${process.env.REACT_APP_S3_PREFIX}/home/banner_3_large.png`,
    event: BannersTrackers.actions.clickOnBanner,
    url: '?financingModal',
    bannerName: 'Financiamento: Financiamento solar sem complicações',
    target: '_self',
  },
  {
    image: `https://77solassets.s3.sa-east-1.amazonaws.com/banners/${process.env.REACT_APP_S3_PREFIX}/home/banner_4.png`,
    mobileImage: `https://77solassets.s3.sa-east-1.amazonaws.com/banners/${process.env.REACT_APP_S3_PREFIX}/home/banner_4_mobile.png`,
    largeImage: `https://77solassets.s3.sa-east-1.amazonaws.com/banners/${process.env.REACT_APP_S3_PREFIX}/home/banner_4_large.png`,
    url: '/rank-77',
    event: BannersTrackers.actions.clickOnBanner,
    bannerName: 'Garanta o seu lugar no ranking da 77',
    target: '_self',
  },
]
