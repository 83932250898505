import { Modal } from '@77sol-ui/molecules'
import { Content } from './components/Content'
import { LoadingAnimated } from './components/LoadingAnimated'
import { type IQuotationModalProps } from './types'
import { useQuotationModal } from './hooks/useQuotationModal'
import { useCancelQuotationContainer } from './hooks/useCancelQuotationModal'

export function QuotationModal({ open, onOpenChange }: IQuotationModalProps) {
  const { isLoading, isError, requestFallback, handleCreateQuotation } =
    useQuotationModal({ onOpenChange })

  const { handleCancelQuotationRequest } = useCancelQuotationContainer()

  return (
    <Modal.Root open={open} onOpenChange={onOpenChange}>
      <Modal.Portal>
        <Modal.Overlay zIndex={1000}>
          {!isLoading && (
            <Content
              onCreateQuotation={handleCreateQuotation}
              onCancel={handleCancelQuotationRequest}
              defaultValues={requestFallback}
            />
          )}
        </Modal.Overlay>
        {isLoading && !isError && <LoadingAnimated />}
      </Modal.Portal>
    </Modal.Root>
  )
}
