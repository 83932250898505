import { service } from '../services/api'
import { useQuery } from '@tanstack/react-query'
import { GET_ME } from '../constants/endpointKeys'

export const useAuthMe = () => {
  const data = useQuery({
    queryKey: [GET_ME],
    queryFn: async () => {
      return await service.get()
    },
  })

  return data
}
