import { useCallback, useEffect } from 'react'
import { Header } from 'components/NewHeader'
import { Page, Carousel } from 'components'
import { SolLeadAlert } from 'containers/SolLeadAlert'
import { SummaryRankClassification } from 'containers'
import { Performance } from './components/Performance'
import { FinancingAndProposalsTable } from './components/FinancingAndProposalsTable'
import { useQuotationModalContext } from 'context/QuotationModalContext'
import { useCreateFinancingPerValueModalContext } from 'containers/Financing/PerValue/CreateFinancingPerValueModal/context/CreateFinancingPerValueModalContext'
import * as S from './styles'
import { useLocation } from 'react-router-dom'
import api from 'api'

export function Home() {
  const { setFinancingPerValueModalOpen } =
    useCreateFinancingPerValueModalContext()
  const { setIsQuotationModalOpen } = useQuotationModalContext()
  const { search } = useLocation()

  const handleShowModals = useCallback(async () => {
    const { data: authMeResponse } = await api.get('/auth/me')

    const showQuotationModal =
      (search.includes('quotationModal') ||
        authMeResponse?.[0]?.open_quotation_modal) &&
      !search.includes('fromModal')

    if (showQuotationModal) {
      setIsQuotationModalOpen(true)
    }

    if (authMeResponse?.[0]?.open_quotation_modal) {
      sessionStorage.setItem('@77sol:new_user', 'true')
    } else {
      sessionStorage.removeItem('@77sol:new_user')
    }

    if (search.includes('financingModal')) {
      setFinancingPerValueModalOpen()
    }
  }, [search])

  useEffect(() => {
    handleShowModals()
  }, [handleShowModals])

  return (
    <Page title="Home">
      <Header />
      <S.CarouselWrapper>
        <Carousel />
      </S.CarouselWrapper>
      <S.Container>
        <SolLeadAlert />
        <FinancingAndProposalsTable />
        <SummaryRankClassification />
        <Performance />
      </S.Container>
    </Page>
  )
}
