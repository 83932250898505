import { useEffect, useState } from 'react'
import {
  Dropdown,
  Typography,
  Input,
  Button,
  Badge,
  useToast,
} from '@77sol/core'
import { Box, Grid, Paper, Hidden } from '@material-ui/core'
import Cards from 'react-credit-cards'
import { ArrowChevronBackIcon } from '@77sol/icons/dist'
import palette from 'app_palette'
import 'react-credit-cards/es/styles-compiled.css'
import API from 'api'
import { useUserHasFullRegistration } from 'hooks/useUserHasFullRegistration'
import styles from './styles'
import ResponseMessage from './components/ResponseMessage'
import DifferentialList from '../components/differentialList'
import './style-card.scss'
import SelectionFolder from './77sol_selection_folder.pdf'
import { cpf, cnpj } from 'cpf-cnpj-validator'

const checkAllEmpty = (values) =>
  Object.values(values).some((value) => value === '')

const handleErrors = (values) => {
  if (checkAllEmpty(values)) {
    return '* É necessário preencher todos os campos'
  }
  if (values.documentType === 'cpf' && !cpf.isValid(values.documentNumber)) {
    return 'O CPF informado é inválido.'
  }
  if (values.documentType === 'cnpj' && !cnpj.isValid(values.documentNumber)) {
    return 'O CNPJ informado é inválido.'
  }
  if (values.cardNumber.length < 13) {
    return 'O número do cartão de crédito informado é inválido.'
  }

  return ''
}

const initialValues = {
  cardNumber: '',
  cardHolderName: '',
  cardExpirationDate: '',
  cardVerificationCode: '',
  documentType: '',
  documentNumber: '',
}

const initialResponseError = {
  errorField: '',
  errorMessage: '',
}

export function Checkout({
  onBack,
  onClose,
  responseMessage,
  setResponseMessage,
  valueMonthlyPayment,
}) {
  const [paymentMethodSelected, setPaymentMethodSelected] =
    useState('creditCard')
  const [error, setError] = useState('')
  const [cardError, setCardError] = useState([])
  const [showError, setShowError] = useState(false)
  const [paymentValues, setPaymentValues] = useState(initialValues)

  const classes = styles()
  const { onErrorOpenToast } = useToast()
  const { validateUserRegistration } = useUserHasFullRegistration()

  const [billetUrl, setBilletUrl] = useState('')
  const [loadingRequest, setLoadingRequest] = useState('')
  const [responseError, setResponseError] = useState(initialResponseError)

  useEffect(() => {
    setError(handleErrors(paymentValues))
  }, [paymentValues])

  const handleConfirmBuy = () => {
    if (!validateUserRegistration()) {
      return
    }

    setResponseError({})
    setLoadingRequest(true)

    if (paymentMethodSelected === 'creditCard' && error) {
      setLoadingRequest(false)
      return setShowError(true)
    }

    API.post('/financial/active-plan', {
      payment_method: paymentMethodSelected,
      number: paymentValues.cardNumber,
      cvv: paymentValues.cardVerificationCode,
      printed_name: paymentValues.cardHolderName,
      card_expiration: paymentValues.cardExpirationDate,
      type_document: paymentValues.cardNumber ? paymentValues.documentType : '',
      document: paymentValues.documentNumber,
    })
      .then((res) => {
        if (res.data.billet) {
          setBilletUrl(res.data.billet)
          setResponseMessage('pending_billet')
        } else if (res.data.status === 'pending') {
          setResponseMessage('pending_card')
        } else if (res.data.status === 'paid') {
          setResponseMessage('paid_card')
        }
      })
      .catch(({ response = {} }) => {
        const { message } = response.data || {}
        if (typeof message === 'string') {
          return onErrorOpenToast(message)
        }
        setResponseMessage('error')

        if (response?.data?.status === 'error' || message?.errors) {
          const [firstErrorMessage] = message.translateErros
          const [[errorField, errorMessage]] = Object.entries(firstErrorMessage)
          setCardError([errorField])
          setResponseError({ errorField, errorMessage })
        }

        onErrorOpenToast('Não foi possível processar o pagamento!')
      })
      .finally(() => {
        setLoadingRequest(false)
      })
  }

  const handleChange = (name, value) => {
    setPaymentValues((prevState) => ({
      ...prevState,
      [name]: value,
    }))
  }

  const handleCloseModal = () => {
    onClose()
  }

  const handleBackModal = () => {
    setResponseMessage('')
  }

  return (
    <Box
      width="90vw"
      height="79vh"
      display="flex"
      flexDirection="column"
      style={{ maxWidth: '1680px' }}
    >
      {responseMessage ? (
        <ResponseMessage
          billetUrl={billetUrl}
          onBack={handleBackModal}
          onClose={handleCloseModal}
          responseError={responseError}
          responseMessage={responseMessage}
          paymentMethod={paymentMethodSelected}
        />
      ) : (
        <Box position="relative" height="100%">
          <Hidden smDown>
            <Box position="absolute">
              <Button
                onClick={onBack}
                size="small"
                startIcon={<ArrowChevronBackIcon />}
                variant="text"
                color="primary"
                style={{
                  marginTop: '26px',
                  marginLeft: '26px',
                }}
              >
                Voltar
              </Button>
            </Box>
          </Hidden>
          <Box height="100%" className={classes.productContainer}>
            <Box className={classes.productTable}>
              <Hidden smUp>
                <Button
                  fullWidth
                  onClick={onBack}
                  size="small"
                  startIcon={<ArrowChevronBackIcon />}
                  variant="outlined"
                  color="primary"
                >
                  Voltar
                </Button>
              </Hidden>
              <DifferentialList valueMonthlyPayment={valueMonthlyPayment} />
            </Box>
            <Box className={classes.paymentContainer}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={12}>
                  <Paper
                    elevation={0}
                    style={{
                      padding: '15px',
                      borderRadius: '15px',
                      border: `1px solid ${palette.primary[300]}`,
                    }}
                  >
                    <Typography
                      type="text_small"
                      style={{ color: palette.primary[300] }}
                    >
                      Forma de pagamento
                    </Typography>
                    <Dropdown
                      fullWidth
                      size="small"
                      options={[
                        {
                          label: 'Boleto',
                          value: 'billet',
                        },
                        {
                          label: 'Cartão de crédito',
                          value: 'creditCard',
                        },
                      ]}
                      initialValue={paymentMethodSelected}
                      onChange={setPaymentMethodSelected}
                    />
                  </Paper>
                </Grid>
                {paymentMethodSelected === 'creditCard' && (
                  <>
                    <Grid item xs={12} md={12}>
                      <Cards
                        cvc={paymentValues.cardVerificationCode}
                        expiry={paymentValues.cardExpirationDate}
                        name={paymentValues.cardHolderName}
                        number={paymentValues.cardNumber}
                        placeholders={{ name: 'Nome no cartão' }}
                        locale={{ valid: 'Validade' }}
                      />
                    </Grid>
                    <Grid item xs={12} md={12}>
                      <Input
                        title="Número do cartão*"
                        onChange={(cardNumber) => {
                          setCardError([])
                          handleChange('cardNumber', cardNumber)
                        }}
                        value={paymentValues.cardNumber}
                        mask="card"
                        validationState={
                          cardError.includes('Número do cartão')
                            ? 'invalid'
                            : 'default'
                        }
                      />
                    </Grid>
                    <Grid item xs={12} md={12}>
                      <Input
                        title="Nome no cartão*"
                        value={paymentValues.cardHolderName}
                        onChange={(cardHolderName) =>
                          handleChange('cardHolderName', cardHolderName)
                        }
                      />
                    </Grid>
                    <Grid item xs={12} md={12}>
                      <Dropdown
                        title="Documento*"
                        fullWidth
                        options={[
                          {
                            label: 'CPF',
                            value: 'cpf',
                          },
                          {
                            label: 'CNPJ',
                            value: 'cnpj',
                          },
                        ]}
                        initialValue={paymentValues.documentType}
                        onChange={(documentType) => {
                          handleChange('documentType', documentType)
                          handleChange('documentNumber', '')
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} md={12}>
                      <Input
                        title="Número do documento*"
                        onChange={(documentNumber) =>
                          handleChange('documentNumber', documentNumber)
                        }
                        value={paymentValues.documentNumber}
                        mask={paymentValues.documentType}
                      />
                    </Grid>
                    <Grid item xs={12} md={12}>
                      <Input
                        title="Vencimento do cartão*"
                        onChange={(cardExpirationDate) => {
                          setCardError([])
                          handleChange('cardExpirationDate', cardExpirationDate)
                        }}
                        value={paymentValues.cardExpirationDate}
                        mask="cardExpirationDate"
                        validationState={
                          cardError.includes('Cartão expirado')
                            ? 'invalid'
                            : 'default'
                        }
                      />
                    </Grid>
                    <Grid item xs={12} md={12}>
                      <Input
                        title="Código de segurança*"
                        value={paymentValues.cardVerificationCode}
                        onChange={(cardVerificationCode) => {
                          setCardError([])
                          handleChange(
                            'cardVerificationCode',
                            cardVerificationCode,
                          )
                        }}
                        inputProps={{ maxLength: 4 }}
                        validationState={
                          cardError.includes('CVV') ? 'invalid' : 'default'
                        }
                      />
                    </Grid>
                  </>
                )}

                {showError && (
                  <Grid item xs={12} md={12}>
                    <Typography size="small" color="red" colorWeight="300">
                      {error}
                    </Typography>
                  </Grid>
                )}

                <Grid item xs={12} md={12}>
                  <Paper
                    style={{
                      padding: '15px',
                      borderRadius: '15px',
                      border: `1px solid ${palette.primary[300]}`,
                      display: 'flex',
                      justifyContent: 'space-between',
                    }}
                  >
                    <Badge label="77PRO" color="primary" />
                    <Typography
                      type="text_small"
                      style={{ color: palette.primary[300] }}
                    >
                      Valor:{' '}
                      {parseFloat(valueMonthlyPayment).toLocaleString('pt-br', {
                        style: 'currency',
                        currency: 'BRL',
                      })}
                    </Typography>
                  </Paper>
                </Grid>
                {parseFloat(valueMonthlyPayment) === 77.0 && (
                  <Grid item xs={12} md={12}>
                    <Badge
                      label={
                        <span>
                          A cobrança da mensalidade do 77PRO acontecerá
                          mensalmente e automaticamente. Não se preocupe! Você
                          poderá cancelar a cobrança a qualquer momento.
                          <a
                            href={SelectionFolder}
                            style={{
                              fontWeight: 'bold',
                              textDecoration: 'none',
                            }}
                            download
                          >
                            {' '}
                            Clique aqui para visualizar mais detalhes do 77PRO.
                          </a>
                        </span>
                      }
                      color="primary"
                    />
                  </Grid>
                )}
                <Grid item xs={12} md={12}>
                  <Button
                    isLoading={loadingRequest}
                    onClick={handleConfirmBuy}
                    disabled={
                      paymentMethodSelected === 'creditCard' &&
                      checkAllEmpty(paymentValues)
                    }
                    fullWidth
                  >
                    Finalizar compra
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Box>
      )}
    </Box>
  )
}
