/**
 * Check if some value is null
 * @param {Object} values
 * @param {Array} optionals
 * @returns
 */
export const isNull = (values = {}, optionals = []) => {
  return Object.entries(values).some(([key, value]) => {
    return !value && !optionals.includes(key)
  })
}

export function validateActionProposalButtons(proposal) {
  const hasSomeError =
    Boolean(
      proposal?.quotes?.find((item) => item?.firstKit?.providers === true),
    ) || proposal?.quotes?.length <= 0

  return hasSomeError
}

export function hasDifferentProvidersInQuotation(quotations) {
  return Boolean(quotations?.find((item) => item?.firstKit?.providers === true))
}
